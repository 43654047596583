import React from "react"
import NewPageLayout from "../../components/layout/new-page-layout"
import CompanyHomePage from "../../pages-sections/new-home"

import companyMetaImage from "../../images/companyMeta.jpg"

export default function ForCompany() {
  return (
    <NewPageLayout
      isCandidate={false}
      title="For Companies - WDN"
      description="Workers' Directory Nexus provides a simple and effective way to connect companies with candidates."
      seoImage={companyMetaImage}
    >
      <CompanyHomePage />
    </NewPageLayout>
  )
}
